











































































import { Component, Vue, Model, Prop, Watch, Emit } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class Transfer extends Vue {
  @Model('cancel', { type: Boolean }) private readonly visible!: boolean
  @Prop({ default: '' }) private readonly title!: string
  @Prop({ default: () => [] }) private defaultCandidateData!: Array<any>
  @Prop({ default: () => [] }) private defaultSelectedData!: Array<any>
  private searchText = ''
  private candidateData: Array<any> = []
  private selectedData: Array<any> = []

  private get locale(): any {
    return this.$store.state.locale
  }

  public get filteredCandidateData(): Array<any> {
    const data = cloneDeep(this.candidateData)
    const text = this.searchText.toLowerCase().trim()
    const selectedIds = this.selectedData.map(item => item.id)
    const newData = data.filter(
      item =>
        (this.getLocaleRelation(item)
          ? this.getLocaleRelation(item)
              .toLowerCase()
              .indexOf(text) !== -1
          : item.title.toLowerCase().indexOf(text) !== -1) && !selectedIds.includes(item.id)
    )
    return newData
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(newVal): void {
    if (newVal) {
      this.searchText = ''
      this.selectedData = cloneDeep(this.defaultSelectedData)
      this.candidateData = cloneDeep(this.defaultCandidateData)
    }
  }

  private onSearch(value): void {
    this.searchText = value
  }

  private selectItem(item, idx): void {
    this.selectedData.push(item)
  }

  private selectAll(): void {
    this.selectedData = []
    let existIds = [] as any
    this.filteredCandidateData.forEach(item => {
      if (existIds.includes(item.id)) {
        return
      }
      existIds.push(item.id)
      this.selectedData.push(item)
    })
  }

  private removeAll(): void {
    this.selectedData = []
  }

  private getLocaleRelation(item): string {
    return this.locale === 'zh' ? item.titleWithRelation : item.titleWithRelationEn
  }

  private removeItem(id, index): void {
    this.selectedData.splice(index, 1)
  }

  @Emit('cancel')
  private closeModal(): boolean {
    return false
  }

  @Emit('confirm')
  private confirm(): Array<any> {
    this.closeModal()
    return this.selectedData
  }
}
