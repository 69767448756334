




























































import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { DropDownController, AddressBookController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { guid, toPage, getSchoolInfo } from '@/utils/utils'
import { createPagination } from '@/constant/constant'
// import TableTotal from '@/components/TableTotal.vue'
// import Transfer from '@/components/Transfer.vue'

@Component({
  components: {
    FlexTooltip,
    // TableTotal,
    // Transfer,
  },
})
export default class AddressBook extends Vue {
  private searchText = ''
  // private rootSubmenuKeys: any = ['sub1']
  private menuKeys: any = []

  private groupList: any = []
  // private openKeys: any = ['sub1']
  private pagination: any = createPagination({ pageSize: 30 })
  // private selectedRowKeys: Array<any> = []
  private data: Array<any> = []
  private loading: boolean = false
  private introduction: any = {}

  private exportLoading: boolean = false

  private get selectedSchool(): any {
    const schooInfo = getSchoolInfo()
    return schooInfo
  }

  // private get showChild(): boolean {
  //   return this.$route.name !== 'addressBook'
  // }

  // private currentStudent(): any {
  //   return localStorage.getItem('studentId')
  // }

  // private get student(): any {
  //   return this.$store.state.currentStudent
  // }

  // private get currentStudent(): any {
  //   return this.$store.state.currentStudent?.studentId ? parseInt(this.$store.state.currentStudent.studentId, 10) : undefined
  // }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('addressBook.name'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
        // width: 400 * this.ratio,
      },
      {
        dataIndex: 'position',
        key: 'position',
        title: this.$t('addressBook.position'),
        ellipsis: true,
        scopedSlots: { customRender: 'position' },
        // width: 400 * this.ratio,
      },
      {
        dataIndex: 'categoryDetails',
        ellipsis: true,
        // title: this.$t('addressBook.categoryDetails'),
        title: this.menuKeys.length
          ? this.$t('address.' + this.menuKeys[0])
          : this.$t('addressBook.categoryDetails'),
      },
      {
        dataIndex: 'email',
        ellipsis: true,
        title: this.$t('addressBook.email'),
        scopedSlots: { customRender: 'email' },
        // width: 230 * this.ratio,
      },
    ].filter(item => item)
  }

  /**
   * 搜索框功能
   */
  private onSearch(value): void {
    this.searchText = value
    this.getDropDownInfo()
  }

  private copyEmail(text): void {
    // const text = res.data[2002] + '/courseSelection?csqId=' + id
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', text)
    input.select()
    // ios兼容
    input.setSelectionRange(0, text.length)
    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    }
    document.body.removeChild(input)
    this.$message.success(this.$tc('common.copySuccess'))
  }

  private changeMenu({ item, key, keyPath }): void {
    if (key) {
      this.menuKeys = [key]
      this.getData()
    }
  }

  /**
   * 表格相关
   */
  // private clearSelection(): void {
  //   this.selectedRowKeys = []
  // }

  // private onSelectChange(selectedRowKeys): void {
  //   this.selectedRowKeys = selectedRowKeys
  // }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  // private onOpenChange(openKeys): void {
  //   const latestOpenKey = openKeys.find((key) => this.openKeys.indexOf(key) === -1)
  //   if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
  //     this.openKeys = openKeys
  //   } else {
  //     this.openKeys = latestOpenKey ? [latestOpenKey] : []
  //   }
  // }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    if (this.loading) return
    this.data = []
    this.loading = true
    this.data = this.groupList.filter(group => group.code === this.menuKeys[0])[0].personalList
    this.pagination.total = this.data.length

    /**
     * test
     */
    this.pagination.current = page.current
    toPage(page, this.data, page => {
      this.pagination.current = page
      // this.refresh()
    })

    this.loading = false
  }

  private getDropDownInfo(): void {
    let condition = this.searchText === '' ? undefined : this.searchText.trim()
    AddressBookController.personalBook(condition)
      .then(res => {
        // this.sections = res[0].data
        this.groupList = res.data

        this.menuKeys = this.menuKeys.length
          ? this.menuKeys
          : res.data[0]?.code
          ? [res.data[0]?.code]
          : []
        // this.$forceUpdate
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    // this.refresh()
    this.getDropDownInfo()
  }

  // private created(): void {
  //   // this.getDropDownInfo()
  //   // this.getMemberLIst()
  //   let consition = {
  //     studentId: 776,
  //   }
  //   AddressBookController.personalBook(consition)
  // }

  // private refresh(): void {
  //   this.getData(this.pagination)
  // }

  private fetchData = debounce(() => {
    this.getDropDownInfo()
  }, 500)
}
