



















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class TableTotal extends Vue {
  @Prop({ default: 'count' }) private readonly type!: string
  @Prop({ default: 0 }) private readonly num!: number
  @Prop({ default: 0 }) private readonly selectedNum!: number

  @Emit()
  private clear() {}
}
